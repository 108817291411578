import ru_RU from 'antd/lib/locale/ru_RU';
import en_GB from 'antd/lib/locale/en_GB';
import uk_UA from 'antd/lib/locale/uk_UA';
import { enUS, ru, uk } from 'date-fns/locale';
import { Locale as DateFnsLocale } from 'date-fns';
import { Locale as AntdLocale } from 'antd/lib/locale-provider';
import { Locale } from '../types/Locale';
import ruImage from '../assets/countries/ru.png';
import enImage from '../assets/countries/en.png';
import uaImage from '../assets/countries/ua.png';

// Flags images: https://icons8.ru/icon/set/%D1%84%D0%BB%D0%B0%D0%B3%D0%B8/color

type LocaleSetting = {
  antdLocale: AntdLocale;
  dateFnsLocale: DateFnsLocale;
  image: string;
};

export const locales: Record<string, LocaleSetting> = {
  /*[Locale.RU]: {
    antdLocale: ru_RU,
    dateFnsLocale: ru,
    image: ruImage,
  },*/
  [Locale.UA]: {
    antdLocale: uk_UA,
    dateFnsLocale: uk,
    image: uaImage,
  },
  [Locale.EN]: {
    antdLocale: en_GB,
    dateFnsLocale: enUS,
    image: enImage,
  },
};
