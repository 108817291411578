export const DRAWER_SIDEBAR_VISIBLE = 'DRAWER_SIDEBAR_VISIBLE';
export type ToggleDrawerSidebarVisibleStatusType = {
  type: typeof DRAWER_SIDEBAR_VISIBLE;
  payload: boolean;
};

export const DRAWER_DROPDOWN_VISIBLE = 'DRAWER_DROPDOWN_VISIBLE';
export type ToggleDrawerDropdownVisibleStatusType = {
  type: typeof DRAWER_DROPDOWN_VISIBLE;
  payload: boolean;
};

export type DrawerSidebarActionType =
  | ToggleDrawerSidebarVisibleStatusType
  | ToggleDrawerDropdownVisibleStatusType;
