import React, { ReactElement } from 'react';
import { Spin } from 'antd';
import clsx from 'clsx';
import styles from './Fallback.module.css';

export type FallbackProps = {
  className?: string;
};

export const Fallback = ({ className }: FallbackProps): ReactElement => {
  const spinnerCX = clsx(styles.spinnerWrapper, className);

  return (
    <div className={spinnerCX}>
      <Spin size="large" tip="Loading..." />
    </div>
  );
};
