export const handleRefUrl = (): void => {
  const currentPathname = window.location.pathname;
  if (currentPathname.endsWith('/signup')) {
    const currentSerchParams = new URLSearchParams(window.location.search);
    if (currentSerchParams.get('ref')) {
      localStorage.setItem('ref', window.location.search);
    } else {
      const localStorageSearchParams = localStorage.getItem('ref');
      if (localStorageSearchParams) {
        window.location.replace(window.location.origin + currentPathname + localStorageSearchParams);
      }
    }
  }
};
