import { useCallback } from 'react';

export const AUTH_TOKEN_NAME = 'FINTEGRITY_ACCESS_TOKEN';

type ReturnedType = {
  token: string | null;
  setAccessToken: (value: string) => void;
  removeAccessToken: () => void;
};
export const useAuthToken = (): ReturnedType => {
  const setAccessToken = useCallback((value: string) => {
    localStorage.setItem(AUTH_TOKEN_NAME, value);
  }, []);

  const removeAccessToken = useCallback(() => {
    localStorage.removeItem(AUTH_TOKEN_NAME);
  }, []);

  return {
    token: localStorage.getItem(AUTH_TOKEN_NAME),
    setAccessToken,
    removeAccessToken,
  };
};
