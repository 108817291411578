import React, { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export type ScrollToTopProps = {
  children?: ReactElement | ReactElement[];
};

const ScrollToTop = ({ children }: ScrollToTopProps): ReactElement => {
  const location = useLocation();
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
