import React, { lazy, ReactElement, useEffect } from 'react';
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter,
} from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import { Locale } from './types/Locale';
import { locales } from './i18n/locales';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { RequireAuth } from './components/RequireAuth';

const Auth = lazy(() => import('./pages/Auth'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Forbidden = lazy(() => import('./pages/Forbidden'));

export const App = (): ReactElement => {
  const { i18n } = useTranslation();

  const selectedLocale = i18n.languages.find((item) => locales[item]) || Locale.EN;
  useEffect(() => {
    i18n.changeLanguage(selectedLocale);
  }, [i18n, selectedLocale]);

  const { antdLocale } = locales[selectedLocale];

  return (
    <ConfigProvider locale={antdLocale}>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
              <Route path="/" element={<Navigate to="/dashboard/my-account" />} />
              <Route path="/dashboard" element={<Navigate to="/dashboard/my-account" />} />
              <Route path="/auth/*" element={<Auth />} />
              <Route
                path="/dashboard/*"
                element={(
                  <RequireAuth>
                    <Dashboard />
                  </RequireAuth>
                )}
              />
              <Route path="/forbidden" element={<Forbidden />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </ConfigProvider>
  );
};
