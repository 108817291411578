import { PageTitleActionType, SET_PAGE_TITLE } from './types/Actions';
import { PageTitleType } from './types/Reducer';

const initialState: PageTitleType = {
  title: '',
};

export const pageTitleReducer = (
  state = initialState,
  action: PageTitleActionType,
): PageTitleType => {
  if (!('type' in action)) {
    return state;
  }

  switch (action.type) {
    case SET_PAGE_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    default:
      return state;
  }
};
