import React, { ReactElement } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { Fallback } from '../Fallback';
import { useAuthToken } from '../../hooks/useAuthToken';
import { useCurrentUser } from '../../hooks/useCurrentUser';

export const RequireAuth = ({ children }: { children: JSX.Element }): ReactElement | null => {
  const { token } = useAuthToken();
  const { loading, data: user } = useCurrentUser();
  const location = useLocation();

  if (loading && !user) {
    return <Fallback />;
  }

  if (user && token) {
    return children;
  }

  /*if (error) {
    removeAccessToken();
    if (error.graphQLErrors[0].message === 'Resource was blocked by administrator') {
      document.location.href = '/auth/login?resourceWasBlockedByAdministrator=1';
    } else if (error.graphQLErrors[0].message === 'Blocked IP address') {
      document.location.href = '/auth/login?ipIsBlocked=1';
    } else if (error.graphQLErrors[0].message !== 'Unauthorized') {
      document.location.href = '/auth/login?somethingWrong=1';
    } else {
      document.location.href = '/auth/login';
    }
    return null;
  }*/

  return <Navigate to="/auth/login" state={{ from: location }} replace />;
};
