import {
  DRAWER_DROPDOWN_VISIBLE,
  DRAWER_SIDEBAR_VISIBLE,
  DrawerSidebarActionType,
} from './types/Actions';
import { DrawerSidebarType } from './types/Reducer';

const initialState: DrawerSidebarType = {
  menu: {
    isOpen: false,
  },
  dropdown: {
    isOpen: false,
  },
};

export const drawerSidebarReducer = (
  state = initialState,
  action: DrawerSidebarActionType,
): DrawerSidebarType => {
  if (!('type' in action)) {
    return state;
  }

  switch (action.type) {
    case DRAWER_SIDEBAR_VISIBLE:
      return {
        ...state,
        menu: {
          isOpen: action.payload,
        },
      };
    case DRAWER_DROPDOWN_VISIBLE:
      return {
        ...state,
        dropdown: {
          isOpen: action.payload,
        },
      };
    default:
      return state;
  }
};
