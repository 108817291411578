import i18n from 'i18next';
import ICU from 'i18next-icu';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Locale } from '../types/Locale';

i18n.use(ICU).use(Backend).use(LanguageDetector).use(initReactI18next)
  .init({
    supportedLngs: Object.values(Locale),
  });

export default i18n;
