import { combineReducers } from 'redux';
import { drawerSidebarReducer } from '../components/DrawerSidebar/reducer';
import { pageTitleReducer } from '../components/PageTitle/reducer';
import { socketReducer } from '../socket/reducer';

export const rootReducer = combineReducers({
  socketReducer,
  drawerSidebarReducer,
  pageTitleReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
