import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import 'antd/dist/antd.min.css';
import { Provider } from 'react-redux';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { store } from './init/store';
import { Fallback } from './components/Fallback';
import './i18n';
import { queryClient } from './init/queryClient';

const root = createRoot(document.getElementById('root') as Element);
root.render(
  <Suspense fallback={<Fallback />}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <App />
      </Provider>
    </QueryClientProvider>
  </Suspense>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
