import { SOCKET_CONNECTION, SocketActionType } from './types/Actions';
import { SocketType } from './types/Reducer';

const initialState: SocketType = {
  socket: null,
};

export const socketReducer = (
  state = initialState,
  action: SocketActionType,
): SocketType => {
  if (!('type' in action)) {
    return state;
  }

  switch (action.type) {
    case SOCKET_CONNECTION:
      return {
        ...state,
        socket: action.payload,
      };
    default:
      return state;
  }
};
