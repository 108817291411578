import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { notification } from 'antd';
import { User } from '../types/User';
import { AUTH_TOKEN_NAME, useAuthToken } from './useAuthToken';

type ReturnedType = {
  data: User;
  loading: boolean;
  error?: any,
  refetch: any;
};

export const useCurrentUser = (): ReturnedType => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME);
  const { removeAccessToken } = useAuthToken();

  const fetchUser = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_AUTH_URI}/auth/current-user`, {
      headers: { Authorization: 'Bearer ' + token },
    });
    return data;
  };

  const res = useQuery('currentUser', fetchUser, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    onError: (e: AxiosError) => {
      removeAccessToken();
      notification.error({
        message: e.message as string,
      });
      // queryClient.clear();
      // document.location.href = '/auth/login';

      /*if (error.graphQLErrors[0].message === 'Resource was blocked by administrator') {
        document.location.href = '/auth/login?resourceWasBlockedByAdministrator=1';
      } else if (error.graphQLErrors[0].message === 'Blocked IP address') {
        document.location.href = '/auth/login?ipIsBlocked=1';
      } else if (error.graphQLErrors[0].message !== 'Unauthorized') {
        document.location.href = '/auth/login?somethingWrong=1';
      } else {
        document.location.href = '/auth/login';
      }*/
    },
  });

  return {
    loading: res.isFetching,
    data: res?.data ?? null,
    refetch: res.refetch,
  };
};
